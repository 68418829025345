import { CurrencyUnit } from "./CurrencyUnit"
import { InvoiceDetail } from "./InvoiceDetail"
import { PaymentScheduleItem } from "./PaymentScheduleItem"

export enum InvoiceType {
  //Alış Faturası
  PurchaseInvoice = 1,
  //Satış Faturası
  SaleInvoice = 2,
}

export interface Invoice {
  accountCardId: string
  invoiceType: InvoiceType
  description: string
  totalPrice: number
  subPrice: number
  vatPrice: number
  currencyUnit: CurrencyUnit
  hasPayment: boolean
  tagId: string
  cashbookId: string
  transactionDate: Date
  invoiceDetails: InvoiceDetail[]
  paymentSchedules: PaymentScheduleItem[]
}
